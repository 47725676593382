<template>
  <ContactInfoBackground>
    <div class="row m-0 justify-content-center">
      <SelectedBranch :selectedBranch="selectedBranch" :branchUpdated="branchUpdated"/>
      <SelectBranch :allInternationalBranches="allInternationalBranches" v-model:selectedBranch="selectedBranch" v-model:branchUpdated="branchUpdated"/>
    </div>
  </ContactInfoBackground>
</template>

<script>

import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ContactInfoBackground: defineAsyncComponent(() => import('./components/ContactInfoBackground.vue')),
    SelectedBranch: defineAsyncComponent(() => import('./views/SelectedBranch.vue')),
    SelectBranch: defineAsyncComponent(() => import('./views/SelectBranch.vue'))
  },
  name: 'MainContactInfo',
  data () {
    return {
      allInternationalBranches: null,
      selectedBranch: null,
      branchUpdated: null
    }
  },
  watch: {
    async headOffice () {
      await this.mergeBranches()
    },
    async internationalBranches () {
      await this.mergeBranches()
    }
  },
  async mounted () {
    await this.mergeBranches()
  },
  methods: {
    async mergeBranches () {
      if (this.headOffice && this.internationalBranches) {
        this.allInternationalBranches = this.headOffice.concat(this.internationalBranches)
        if (this.isUK) {
          let i = 0
          for (const item of this.allInternationalBranches) {
            if (item.name === 'United Kingdom') {
              await this.allInternationalBranches.splice(i, 1)
              await this.allInternationalBranches.unshift(item)
            }
            i++
          }
          this.selectedBranch = await this.allInternationalBranches.find(branch => branch.name === 'United Kingdom')
        } else {
          this.selectedBranch = await this.allInternationalBranches.find(branch => branch.name === 'South Africa')
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'headOffice',
      'branches',
      'internationalBranches'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
